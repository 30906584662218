import { useReducer } from 'react';

import UserContext from "./UserContext";

import { processUserInventoryForForge } from "../utils/ForgeUtils";

const defaultUserState = {
    userInfo: {
        guId: -1,
        authToken: "",
        refreshToken: "",
        defaultWallet: "",
        linkedWallets: ["0x0 Default Wallet", "0x1 Secondary Wallet"],
        godsBalance: -1,
        fluxBalance: -1,
        stardustBalance: -1
    },
    userInventory: {},
    setUserInfo: (userInfoObj) => {},
    setUserInventory: (userInventoryObj) => {}
};

const userReducer = (state, action) => {
    if (action.type === 'SET_USER_INFO') {

        let newUserInfoObject = {
            guId: action.userInfo.guId,
            authToken: action.userInfo.authToken,
            refreshToken: action.userInfo.refreshToken,
            defaultWallet: action.userInfo.defaultWallet,
            linkedWallets: action.userInfo.linkedWallets,
            godsBalance: action.userInfo.godsBalance,
            fluxBalance: action.userInfo.fluxBalance,
            stardustBalance: action.userInfo.stardustBalance
        }

        return { userInfo: newUserInfoObject };
    } else if (action.type === 'SET_USER_INVENTORY') {
        return { userInventory: action.userInventory };
    }
    return defaultUserState;
};

const UserProvider = props => {
    const [userState, dispatchUserAction] = useReducer(userReducer, defaultUserState);

    const setUserInfoHandler = (userInfoObj) => {
        dispatchUserAction({type: 'SET_USER_INFO', userInfo: userInfoObj});
    };

    const setUserInventoryHandler = (userInventoryObj) => {
        dispatchUserAction( {type: "SET_USER_INVENTORY", userInventory: userInventoryObj} )
    };

    const userContext = {
        userInfo: userState.userInfo,
        userInventory: userState.userInventory,
        setUserInfo: (userObj) => setUserInfoHandler(userObj),
        setUserInventory: (userInvObj) => setUserInventoryHandler(userInvObj),
    };

    return (
        <UserContext.Provider value={userContext}>
            {props.children}
        </UserContext.Provider>
    );
};

export default UserProvider;