import { NavLink } from 'react-router-dom';
import styles from './MainNavigation.css'

function MainNavigation() {
    return (
        <header className={""} >
            <nav className={""}>
                <ul className={"nav"}>
                    <li className={"nav-item"}>
                        <NavLink to={"/"} className={(isActive) => isActive? 'nav-item-link' : undefined} end>Home</NavLink>
                    </li>
                    {/*<li className={"nav-item"}>*/}
                    {/*    <NavLink to={"/forgeplus"} className={(isActive) => isActive? 'nav-item-link' : undefined}>Forge+</NavLink>*/}
                    {/*</li>*/}
                    {/*<li className={"nav-item"}>*/}
                    {/*    <NavLink to={"/workshopplus"} className={(isActive) => isActive? 'nav-item-link' : undefined}>Workshop+</NavLink>*/}
                    {/*</li>*/}
                    <li className={"nav-item"}>
                        <NavLink to={"/packguide"} className={(isActive) => isActive? 'nav-item-link' : undefined}>Expansion Guide</NavLink>
                    </li>
                    <li className={"nav-item"}>
                        <NavLink to={"/mythicleaderboard"} className={(isActive) => isActive? 'nav-item-link' : undefined}>Mythic Raffle</NavLink>
                    </li>
                </ul>
            </nav>
        </header>
    );
}

export default MainNavigation;