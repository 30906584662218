import { Outlet } from 'react-router-dom';

import MainNavigation from "../../Navigation/MainNavigation";

function RootLayout() {
    return (
        <>
            <MainNavigation />
            <div>
                <Outlet />
            </div>

        </>
    );
}

export default RootLayout;