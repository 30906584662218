import {GuCard} from "../GuCard/GuCard";
import "./GuCraftRecipeDetails.css"

export const GuCraftRecipeDetails = (props) => {
    const isEpicRecipe = true;
    const isLegendaryRecipe = false;

    const RecipeDetailDisplay = (props) => {
        return (
            <div>
                {props.recipeList.map( (recipe) => {
                    return (
                        <div className={"recipe-details-container"}>
                            <h4>Ingredients:</h4>
                            <div className={"recipe-details-card-container"}>
                                {recipe.ingredients.map( (ingredientObj) => {
                                    return (<div>
                                        Quantity: {ingredientObj.quantity}
                                        <GuCard key={ingredientObj.protoId} cardData={ingredientObj} handleOnCardClick={() => {}} />
                                    </div>)
                                })}
                            </div>
                            <h4>Crafts:</h4>
                            <div className={"recipe-details-card-container"}>

                                {recipe.yields.map( (yieldObj) => {
                                    return (<div>
                                        Quantity: {yieldObj.quantity}
                                        <GuCard key={yieldObj.protoId} cardData={yieldObj} handleOnCardClick={() => {}} />
                                    </div>)
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
        );
    };

    return (
        <div>
            {isEpicRecipe && <div>
                <RecipeDetailDisplay recipeList={props.cardData.recipes} cardData={props.cardData} />
            </div>}
            {isLegendaryRecipe && <div>
                <RecipeDetailDisplay recipeList={props.cardData.recipes} cardData={props.cardData} />
            </div>}
        </div>
    );
}