import { useEffect, useState } from "react";

import { styled } from '@mui/system';
import {Link, Table, TableBody, TableCell, TableHead, TableRow, TextField} from '@mui/material';
import {
    TablePagination,
    tablePaginationClasses as classes,
} from '@mui/base/TablePagination';
import { tableCellClasses } from '@mui/material/TableCell';

import {gu_pythiasdeckbox_get_mythicRaffleLeaderboardData} from "../../../GuApiConstants";

import "./RaffleLeaderboard.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 14,
        color: "white",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: "white"
    },
}));

const CustomTablePagination = styled(TablePagination)`
  & .${classes.toolbar} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    color: white;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
  }

  & .${classes.displayedRows} {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.spacer} {
    display: none;
  }

  & .${classes.actions} {
    display: flex;
    gap: 0.25rem;
  }
`;

export const RaffleLeaderboardPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [leaderboardRankingsArray, setLeaderboardRankingsArray] = useState([]);
    const [leaderboardUpdateDate, setLeaderboardUpdateDate] = useState("");
    const [leaderboardNextUpdateDate, setLeaderboardNextUpdateDate] = useState("");

    const [rankingsPage, setRankingsPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const [addressSearch, setAddressSearch] = useState('');

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        rankingsPage > 0 ? Math.max(0, (1 + rankingsPage) * rowsPerPage - leaderboardRankingsArray.length) : 0;

    const handleChangePage = (event, newPage) => {
        setRankingsPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setRankingsPage(0);
    };

    const handleAddressChange = (value) => {
        if (value === "") {
            setRowsPerPage(25);
        } else {
            setRowsPerPage(-1);
        }

        setAddressSearch(value);
    };

    useEffect( () => {

        const fetchMythicLeaderboardData = async () => {
            setIsLoading(true);

            const leaderboardResponse = await fetch(gu_pythiasdeckbox_get_mythicRaffleLeaderboardData);
            const leaderboardData = await leaderboardResponse.json();

            if (leaderboardData) {
                setLeaderboardRankingsArray(leaderboardData.rankings);
                setLeaderboardUpdateDate(leaderboardData.snapshotDate);
                setLeaderboardNextUpdateDate(leaderboardData.nextUpdateDate);
                setIsLoading(false);
            }
        };

        fetchMythicLeaderboardData();
    }, []);

    return (
        <div className={"leaderboard-page-bg"}>
            <div className={"leaderboard-page"}>
                <h3 className={"leaderboard-page-title"}>Mythic Variant 1 - Blade of the Creator Alternate Art Raffle Leaderboard</h3>
                {isLoading && (<div>Data is loading, please wait</div>)}
                {!isLoading && (
                    <div className={"leaderboard-table-container"}>
                        <div className={"mythic-raffle-info-bar"}>
                            <div>
                                <b>Next Update:</b> {leaderboardNextUpdateDate}
                            </div>
                            <div>
                                <b>Last Update:</b> {leaderboardUpdateDate}
                            </div>
                        </div>
                        <TextField id="address-search-input" value={addressSearch} sx={{
                            input: {
                                color: "black",
                                background: "rgba(255, 255, 255, 1)",
                            }
                        }} onChange={(event) => {
                            handleAddressChange(event.target.value);
                        }} className={"filter-options-bar-item"} label={"Search by Address"} variant={"filled"} size={"small"} fullWidth />
                        <Table sx={{ minWidth: 500 }} size="small" aria-label="simple leaderboard table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">Position</StyledTableCell>
                                    <StyledTableCell align="center">User Address</StyledTableCell>
                                    <StyledTableCell align="center">Tickets</StyledTableCell>
                                    <StyledTableCell align="center" sx={{minWidth: 115}}>% of Total Tickets</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                        ? leaderboardRankingsArray.slice(rankingsPage * rowsPerPage, rankingsPage * rowsPerPage + rowsPerPage)
                                        : leaderboardRankingsArray
                                ).filter((entry) => {
                                    let loweredEntryAddress = entry.address.toLowerCase();
                                    let loweredSearch = addressSearch.toLowerCase();

                                    if (addressSearch === "" || loweredEntryAddress.includes(loweredSearch)) {
                                        return true;
                                    }
                                    return false;
                                }).map((row, idx) => (
                                    <TableRow
                                        key={row.address}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, color: "rgba(255, 255, 255, 1)" }}
                                    >
                                        <StyledTableCell align="center" component="th" scope="row">
                                            {row.leaderboardPosition}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">{row.address}</StyledTableCell>
                                        <StyledTableCell align="center">{row.mythicTickets}</StyledTableCell>
                                        <StyledTableCell align="center">{row.ticketPercentage}%</StyledTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <tfoot>
                            <tr>
                                <CustomTablePagination
                                    rowsPerPageOptions={[25, 50]}
                                    colSpan={4}
                                    count={leaderboardRankingsArray.length}
                                    rowsPerPage={rowsPerPage}
                                    page={rankingsPage}
                                    slotProps={{
                                        select: {
                                            'aria-label': 'rows per page',
                                        },
                                        actions: {
                                            showFirstButton: true,
                                            showLastButton: true,
                                        },
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </tr>
                            </tfoot>
                        </Table>
                    </div>
                )}
            </div>
        </div>
    );
}