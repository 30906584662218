import React from 'react';

const UserContext = React.createContext({
    userInfo: {
        guId: -1,
        authToken: "",
        refreshToken: "",
        defaultWallet: "",
        linkedWallets: ["0x0 Default Wallet", "0x1 Secondary Wallet"],
        godsBalance: -1,
        fluxBalance: -1,
        stardustBalance: -1
    },
    userInventory: {},
    setUserInfo: (userObj) => {},
    setUserInventory: (userInventory) => {}
});

export default UserContext;