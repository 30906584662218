import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useState } from 'react';
import { createBrowserRouter, RouterProvider, useLocation } from 'react-router-dom';

import UserProvider from "./store/UserProvider";

import UserAuthenticationModal from "./components/UserAuthenticationModal/UserAuthenticationModal";
import RootLayout from "./components/pages/root/RootLayout";
import HomePage from './components/pages/home/Home.js'
import ForgePlusPage from './components/pages/fasterforge/ForgePlus.js'
import WorkshopPlusPage from "./components/pages/workshopplus/WorkshopPlus";
import ErrorPage from "./components/pages/error/Error";
import { PackGuide } from "./components/pages/packguide/PackGuide";
import { RaffleLeaderboardPage } from "./components/pages/mythicraffleleaderboard/RaffleLeaderboard";

function App() {
    //const currentPage = useLocation();

    // //TODO: This should probably be done better with useMemo/useEffect but I don't quite understand how those work yet so we will deal with this inefficiency for now
    // const calculateAuthWithGuModalVisibility = () => {
    //
    //     console.log(router.path); // currentPage.pathname);
    //
    //     return false;
    // };
    //
    // calculateAuthWithGuModalVisibility();
    const [isAuthWithGuModalVisible, setIsAuthWithGuModalVisible] = useState(false);

    const showAuthWithGuModalHandler = () => {
        setIsAuthWithGuModalVisible(true);
    };

    const hideAuthWithGuModalHandler = () => {
        setIsAuthWithGuModalVisible(false);
    };

    // Moving this into App component to be able to pass modal handler props, unfortunately this means this is re-rendered every time App is re-rendered
// It used to be defined outside of App, but I am unsure how to pass the handlers that way.
    const router = createBrowserRouter([
        { path: '/',
            element: <RootLayout />,
            errorElement: <ErrorPage />,
            children: [
                { path: '/', element: <HomePage /> },
                { path: '/packguide', element: <PackGuide />},
                { path: '/packguide/:protoId', element: <PackGuide />},
                { path: '/mythicleaderboard', element: <RaffleLeaderboardPage />},
            ],
        },
    ]);

    return (
        <UserProvider>
            {isAuthWithGuModalVisible &&
                <UserAuthenticationModal onShowAuthWithGuModalHandler={showAuthWithGuModalHandler} onHideAuthWithGuModalHandler={hideAuthWithGuModalHandler} />}
            <RouterProvider router={router} />
        </UserProvider>
    );
}

export default App;
