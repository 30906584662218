import ReactDOM from "react-dom";

import styles from "./BaseModal.module.css";

const Backdrop = (props) => {
    return <div className={styles.backdrop} ></div>
};

const ModalOverlay = (props) => {
    return (
        <div className={styles.modal}>
            <div className={styles.content}>{props.children}</div>
        </div>
    )
}

const portalElement = document.getElementById('overlays')

const BaseModal = (props) => {

    return (
        <>
            {ReactDOM.createPortal(<Backdrop />, portalElement)}
            {ReactDOM.createPortal(<ModalOverlay>{props.children}</ModalOverlay>, portalElement)}
        </>
    );
};

export default BaseModal;