import "./GuCard.css";

export const GuCard = (props) => {

    const handleOnClick = () => {
        props.handleOnCardClick(props.cardData.protoId);
    };

    return (
        <div onClick={handleOnClick} className={ (props.isSelected)? "gucard gucard-selected" : "gucard"}>
            <img src={props.cardData.imgSource} onClick={handleOnClick} />
        </div>
    );
}