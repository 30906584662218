import "./GuCardDetailsDrawer.css"

import { useState } from "react";
import { Drawer, Divider, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { styled, useTheme } from "@mui/material/styles";
import {GuCraftRecipeDetails} from "../GuCraftRecipeDetails/GuCraftRecipeDetails";

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

export const GuCardDetailsDrawer = (props) => {

    const crystalBoosterDropPercentTotal = props.cardData.crystalBooster;
    const crystalSuperBoosterDropPercentTotal = props.cardData.crystalSuperBooster
    const crystalShinyDropPercentTotal = props.cardData.crystalShiny;
    const crystalSuperShinyDropPercentTotal = props.cardData.crystalSuperShiny;

    const fateBoosterDropPercentTotal = props.cardData.fateBooster;
    const fateSuperBoosterDropPercentTotal = props.cardData.fateSuperBooster;
    const fateMegaBoosterDropPercentTotal = props.cardData.fateMegaBooster;

    const sealedCommonDropPercentTotal = props.cardData.sealedCommon;
    const sealedRareDropPercentTotal = props.cardData.sealedRare;
    const sealedEpicDropPercentTotal = props.cardData.sealedEpic;
    const sealedLegendaryDropPercentTotal = props.cardData.sealedLegendary;

    const generateCardMetaDataForEmbed = () => {
        let headElements = document.getElementsByTagName("head")[0];

        for (const elem of headElements.children) {
            if (elem?.attributes?.name?.value === "title") {
                elem.attributes.content.value = props.cardData.cardName;
            }
            if (elem?.attributes?.name?.value === "description") {
                let crystalInfoString = (props.cardData.isPurchasable)? `
                    Crystal: ${crystalBoosterDropPercentTotal}                 
                    Crystal Super: ${crystalSuperBoosterDropPercentTotal}
                    Crystal Shiny: ${crystalShinyDropPercentTotal}
                    Crystal Super Shiny: ${crystalSuperShinyDropPercentTotal}` : "";
                let fateInfoString = (props.cardData.isEarnableWeekendRanked)? `
                    Fate: ${fateBoosterDropPercentTotal}                 
                    Fate Super: ${fateSuperBoosterDropPercentTotal}
                    Fate Mega: ${fateMegaBoosterDropPercentTotal}` : "";
                let sealedInfoString = (props.cardData.isEarnableSealed)? `
                    Sealed Common: ${sealedCommonDropPercentTotal}                 
                    Sealed Rare: ${sealedRareDropPercentTotal}
                    Sealed Epic: ${sealedEpicDropPercentTotal}
                    Sealed Legendary: ${sealedLegendaryDropPercentTotal}` : "";
                let craftedInfoString = (props.cardData.isCraftable)? `Only available via Crafting` : ""

                elem.attributes.content.value = crystalInfoString + fateInfoString + sealedInfoString + craftedInfoString;
                break;
            }
        }
    };
    generateCardMetaDataForEmbed();

    return (
        <div>
            <DrawerHeader>
                <IconButton onClick={props.handleOnDrawerClose}>
                    <CloseIcon />
                </IconButton>
                <div style={{margin: "auto"}}>
                    <b>{props.cardData.protoId} - {props.cardData.cardName}</b>
                </div>
            </DrawerHeader>
            <Divider />
            <div className={"drawer-container"}>
                <h3>Available In:</h3>
                {props.cardData.isPurchasable && <div>
                    <h4>Crystal Packs</h4>
                    <div>
                        Crystal Booster Pack: <b>{crystalBoosterDropPercentTotal}%</b>
                    </div>
                    <div>
                        Crystal Super Booster Pack: <b>{crystalSuperBoosterDropPercentTotal}%</b>
                    </div>
                    <div>
                        Crystal Shiny Pack: <b>{crystalShinyDropPercentTotal}%</b>
                    </div>
                    <div>
                        Crystal Super Shiny Pack: <b>{crystalSuperShinyDropPercentTotal}%</b>
                    </div>
                </div>}
                {props.cardData.isEarnableWeekendRanked && <div>
                    <h4>Fate Packs</h4>
                    <div>
                        Fate Booster Pack: <b>{fateBoosterDropPercentTotal}%</b>
                    </div>
                    <div>
                        Fate Super Booster Pack: <b>{fateSuperBoosterDropPercentTotal}%</b>
                    </div>
                    <div>
                        Fate Mega Booster Pack: <b>{fateMegaBoosterDropPercentTotal}%</b>
                    </div>
                </div>}
                {props.cardData.isEarnableSealed && <div>
                    <h4>Sealed Packs</h4>
                    <div>
                        Sealed Common Pack: <b>{sealedCommonDropPercentTotal}%</b>
                    </div>
                    <div>
                        Sealed Rare Pack: <b>{sealedRareDropPercentTotal}%</b>
                    </div>
                    <div>
                        Sealed Epic Pack: <b>{sealedEpicDropPercentTotal}%</b>
                    </div>
                    <div>
                        Sealed Legendary Pack: <b>{sealedLegendaryDropPercentTotal}%</b>
                    </div>
                </div>}
                {props.cardData.isCraftable && <div>
                    {<GuCraftRecipeDetails cardData={props.cardData} />}
                </div>}
            </div>
        </div>
    );
}