
const HomePage = () => {
    return (
        <div style={{padding: "0px 30px"}}>
            <h2>Home Page</h2>
            <div>
                <h4>If you have any constructive feedback or feature requests(dont we all) feel free to reach out via twitter or Discord!</h4>
                <p>Still a good deal planned and in the works, check back periodically to see any new updates</p>
                <p>Soon to be home of Faster Forge Web</p>
            </div>
        </div>
    );
}

export default HomePage;