import styles from "./UserAuthenticationModal.module.css";
import {Button, TextField} from "@mui/material";

import { useContext, useState, useRef} from "react";

import { gu_api_authentication_refresh_url, gu_pythiasdeckbox_get_userinfo_by_guid_url } from "../../GuApiConstants";

import UserContext from "../../store/UserContext";

import BaseModal from "../Modal/BaseModal";

const UserAuthenticationModal = (props) => {
    const userContext = useContext(UserContext);
    // const [isAuthModalVisible, setIsAuthModalVisible] = useState(true);

    //ood04sc2qquwfgvmg68uoibqqxfl5qnu
    const guIdRef = useRef();
    const userRefreshTokenRef = useRef();

    const getUserInfoFromPythiasDeckboxApiByGuId = () => {
        let request = new XMLHttpRequest();
        request.open('GET', gu_pythiasdeckbox_get_userinfo_by_guid_url + userContext.userInfo?.guId);
        request.setRequestHeader("Content-Type", "application/json");
        request.responseType = 'json';
        request.onload = () => {

            console.log(request);

            if ( request.status === 200 ){
                let newUserInfo = userContext.userInfo;

                newUserInfo.defaultWallet = request.response.defaultWallet;
                newUserInfo.linkedWallets = request.response.linkedWallets;
                newUserInfo.godsBalance = request.response.godsBalance;
                newUserInfo.fluxBalance = request.response.fluxBalance;
                newUserInfo.stardustBalance = request.response.stardustBalance;

                userContext.setUserInfo(newUserInfo);
            }
        }

        request.onerror = () => {
            console.log("Failed to get user wallet addresses :(");
        }

        request.send();
    };

    const handleUserLoginClick = async () => {
        let requestAuth = new XMLHttpRequest();
        requestAuth.open('POST', gu_api_authentication_refresh_url);
        requestAuth.setRequestHeader("Content-Type", "application/json");
        requestAuth.responseType = 'json';
        requestAuth.onload = () => {

            console.log(requestAuth);

            if ( requestAuth.status === 200 ){

                let userInfoRequest = new XMLHttpRequest();
                userInfoRequest.open('GET', gu_pythiasdeckbox_get_userinfo_by_guid_url + requestAuth.response.id);
                userInfoRequest.setRequestHeader("Content-Type", "application/json");
                userInfoRequest.responseType = 'json';
                userInfoRequest.onload = () => {

                    console.log(userInfoRequest);

                    if ( userInfoRequest.status === 200 ){
                        let newUserInfo = userContext.userInfo;

                        newUserInfo.guId = requestAuth.response.id;
                        newUserInfo.authToken = requestAuth.response.access;
                        newUserInfo.refreshToken = requestAuth.response.refresh;
                        newUserInfo.defaultWallet = userInfoRequest.response.defaultWallet;
                        newUserInfo.linkedWallets = userInfoRequest.response.linkedWallets;
                        newUserInfo.godsBalance = userInfoRequest.response.godsBalance;
                        newUserInfo.fluxBalance = userInfoRequest.response.fluxBalance;
                        newUserInfo.stardustBalance = userInfoRequest.response.stardustBalance;

                        userContext.setUserInfo(newUserInfo);

                        props.onHideAuthWithGuModalHandler();
                    }
                }

                userInfoRequest.onerror = () => {
                    console.log("Failed to get user wallet addresses :(");
                }

                userInfoRequest.send();
            }
        }

        requestAuth.onerror = () => {
            console.log("Failed to get refresh user authentication :(");
        }

        requestAuth.send(JSON.stringify({
            "refresh": userRefreshTokenRef.current.value
        }));
    };

    return (
        <BaseModal>
            <h2>
                Unofficial "Log In" to Gods Unchained
            </h2>
            <div>
                <p>Welcome! It looks like you're trying to do something that requires talking to the <b>official Gods Unchained servers</b>. If you successfully provide the
                    information below you'll be able take advantage of the extra functionality provided here. Otherwise click the back button to return to a previous
                    page where this isn't required. (More Info)</p>
                <div className={styles.modalInputBody}>
                    <div className={styles.modalInputItem}>
                        <TextField size="small" label="Numerical GU ID:"  id="guIdInput" inputRef={guIdRef} />
                    </div>
                    <div className={styles.modalInputItem}>
                        <TextField size="small" label="GU Refresh Token: "  id="guRefreshToken" inputRef={userRefreshTokenRef} autoComplete="off" />
                    </div>

                    <div className={styles.modalInputItem}>
                        <p>
                            <b>Disclaimer:</b> By accepting this acknowledgement and using the tools on PythiasDeckbox.com you accept any associated risks.
                            Some functionality on this Website requires that you provide a REFRESH TOKEN used by the Official Gods Unchained Launcher which both
                            the Launcher and this Website uses to take actions on your behalf. It is necessary for example, in order to generate Forge
                            URLs automatically <b>HOWEVER</b> it can be used for any Gods Unchained Account related actions but <b>DOES NOT ALLOW
                            the Website to SIGN WALLET TRANSACTIONS</b>.
                            Your Gods Unchained REFRESH TOKEN is only used by the Website while it's open, to refresh your login to ensure you can continue to Forge and complete forge or other GU activities.
                            If you are worried, anxious, or hesitant... good. This acknowledgement has served it's purpose. As always... stay safe, and if you decide to use the Website I very much look forward
                            to your feedback for improvements!
                        </p>
                    </div>
                    <div className={styles.modalInputItem}>
                        <Button variant="contained" onClick={handleUserLoginClick}>Acknowledge and Log In!</Button>
                    </div>
                </div>
            </div>
        </BaseModal>
    );
};

export default UserAuthenticationModal;