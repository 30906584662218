import MainNavigation from '../../Navigation/MainNavigation.js';

function ErrorPage() {
    return (
        <>
            <MainNavigation />
            <main>
                <h2>An Error Occurred</h2>
                <p>It looks like something happened that wasn't supposed to, sorry!</p>
            </main>
        </>
    );
}

export default ErrorPage;