import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import {TextField, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, FormGroup, ListItemText} from "@mui/material";
import { Drawer } from "@mui/material";

import "./PackGuide.css";
import {GuCard} from "../../GuCard/GuCard";
import {GuCardDetailsDrawer} from "../../GuCardDetailsDrawer/GuCardDetailsDrawer";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const dropSourceFilterTypeList = ["All", "Purchasable", "Weekend Ranked", "Sealed", "Crafted", "Craft Ingredient", "Swag"];

export const PackGuide = () => {

    const { protoId } = useParams();
    console.log(`Path Parameter: ${protoId}`);

    const [isLoading, setIsLoading] = useState(false);

    const [tofCardsMap, setTofCardsMap] = useState(null);
    const [tofCardsArray, setTofCardsArray] = useState([]);

    const [selectedGuCard, setSelectedGuCard] = useState({});
    const [isCardSelected, setIsCardSelected] = useState(false);

    const [rarityFilter, setRarityFilter] = useState("");

    const [cardNameSearch, setCardNameSearch] = useState('');
    const [sourceFilterSelectValue, setSourceFilterSelectValue] = useState(["All"]);

    const handleRarityFilterChange = (event) => {
        setRarityFilter(event.target.value);
    };

    const handleSourceFilterSelectValueChange = (event) => {
        let value = event.target.value;

        if (value.includes("All") && !sourceFilterSelectValue.includes("All")) {
            value = ["All"];
        } else if (sourceFilterSelectValue.includes("All") && value.length > 1) {
            value = value.filter( (filter) => filter !== "All");
        }

        setSourceFilterSelectValue(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    useEffect( () => {

        const fetchTofExpansionData = async () => {
            setIsLoading(true);

            const expResponse = await fetch(`https://9ldhlj74p7.execute-api.us-east-2.amazonaws.com/api/v0/data/expansionPackRates`);
            const expansionData = await expResponse.json();

            let cardDataArray = [];
            for (const cardDataKey in expansionData) {
                cardDataArray.push(expansionData[cardDataKey]);
            }

            let pathParamSelectedCard = {};
            if (protoId !== undefined) {
                if (expansionData.hasOwnProperty(protoId)) {
                    pathParamSelectedCard = expansionData[protoId];

                    setSelectedGuCard(pathParamSelectedCard);
                    setIsCardSelected(true);
                }
            }

            setTofCardsArray(cardDataArray);
            setTofCardsMap(expansionData);
            setIsLoading(false);
        };

        fetchTofExpansionData();
    }, []);

    const handleOnCardClick = (cardId) => {
        if (selectedGuCard.protoId === cardId) {
            handleOnDrawerClose();

            setSelectedGuCard({});
            setIsCardSelected(false);
        } else {
            setSelectedGuCard(tofCardsMap[cardId]);
            setIsCardSelected(true);
        }
    };

    const handleOnDrawerClose = () => {
        setSelectedGuCard({protoId: -1, cardName: ""});
        setIsCardSelected(false);
    };

    return (
        <div>
            <div>
                <h2 className={"header-test"}>Visual Expansion Guide (BETA): Tides of Fate </h2>
                <FormGroup className={"filter-options-bar"} row={true}>
                    <TextField id="standard-basic" value={cardNameSearch} onChange={(event) => {
                        setCardNameSearch(event.target.value);
                    }} className={"filter-options-bar-item"} label={"Search by Card Name"} variant={"outlined"} size={"small"} />
                    <div className={"filter-options-bar-item rarity-select-container"}>
                        <InputLabel id="rarity-select-label" className={"select-label"}>Rarity</InputLabel>
                        <Select
                            labelId="rarity-select-label"
                            id="rarity-simple-select"
                            placeholder="Select Rarity"
                            size={"small"}
                            value={rarityFilter}
                            onChange={handleRarityFilterChange}
                        >
                            <MenuItem value={""}>None</MenuItem>
                            <MenuItem value={"common"}>Common</MenuItem>
                            <MenuItem value={"rare"}>Rare</MenuItem>
                            <MenuItem value={"epic"}>Epic</MenuItem>
                            <MenuItem value={"legendary"}>Legendary</MenuItem>
                        </Select>
                    </div>
                    <div className={"filter-options-bar-item rarity-select-container"}>
                        <InputLabel id="drop-source-filter-select-label" className={"select-label"}>Drop Source Filter</InputLabel>
                        <Select
                            labelId="drop-source-filter-select-label"
                            id="drop-source-filter-select"
                            multiple
                            value={sourceFilterSelectValue}
                            onChange={handleSourceFilterSelectValueChange}
                            renderValue={(selected) => selected.join(', ')}
                            size={"small"}
                            MenuProps={MenuProps}
                        >
                            {dropSourceFilterTypeList.map((sourceFilter) => (
                                <MenuItem key={sourceFilter} value={sourceFilter}>
                                    <Checkbox checked={sourceFilterSelectValue.indexOf(sourceFilter) > -1} />
                                    <ListItemText primary={sourceFilter} />
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </FormGroup>
                {isLoading && (<div>Data is loading, please wait</div>)}
                {!isLoading && (
                    <div className={"cards-container-v2"}>
                    {tofCardsArray.filter( (card) => {
                        let loweredCardName = card.cardName.toLowerCase();
                        let loweredSearch = cardNameSearch.toLowerCase();

                        let loweredCardRarity = card.rarity.toLowerCase();
                        let loweredRarityFilter = rarityFilter.toLowerCase();

                        let selectedFiltersList = (sourceFilterSelectValue.length > 0) ? sourceFilterSelectValue.map( (filterVal) => {
                            return filterVal.toLowerCase().replace(' ', '');
                        })  : ["all"];

                        if (cardNameSearch === "" || loweredCardName.includes(loweredSearch)) {
                            if (loweredRarityFilter === "" || loweredCardRarity === loweredRarityFilter) {
                                if (selectedFiltersList.includes("all")) {
                                    return true;
                                } else if (selectedFiltersList.includes("all")) {
                                    return true;
                                } else if (card.isPurchasable && selectedFiltersList.includes("purchasable")) {
                                    return true;
                                } else if (card.isEarnableWeekendRanked && selectedFiltersList.includes("weekendranked")) {
                                    return true;
                                } else if (card.isEarnableSealed && selectedFiltersList.includes("sealed")) {
                                    return true;
                                } else if (card.isCraftable && selectedFiltersList.includes("crafted")) {
                                    return true;
                                } else if (card.isCraftIngredient && selectedFiltersList.includes("craftingredient")) {
                                    return true;
                                } else if (card.isSwag && selectedFiltersList.includes("swag")) {
                                    return true;
                                }

                                return false;
                            } else {
                                return false;
                            }
                        } else {
                            return false;
                        }
                    }).map( (cardObject) => {
                        return (
                            <GuCard key={cardObject.protoId} isSelected={selectedGuCard.protoId === cardObject.protoId} cardData={cardObject} handleOnCardClick={handleOnCardClick} />
                        );
                    })}
                    </div>)}
                <Drawer open={isCardSelected} anchor={"right"} variant={"persistent"} >
                    <GuCardDetailsDrawer cardData={selectedGuCard} handleOnDrawerClose={handleOnDrawerClose} />
                </Drawer>
            </div>

        </div>
    );
}